
.ion-card-title {
    display: inline-block !important;
    font-size: var(--font-size-title-h5);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-base);
    text-align: left;
    vertical-align: -webkit-baseline-middle;
    color: black;
}

.ion-card-sub-title {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    text-align: left;
}

/*.ion__icon--kindmap {
    max-width: 100%;
    height: auto;
    vertical-align: -webkit-baseline-middle;
}*/

.ion-icon {
    max-width: 100%;
    height: auto;
    vertical-align: -webkit-baseline-middle;
}

.no-padding-grid {
    padding: 0px;
}

/*.ion-icon-lock {
    right: 0;
    top: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    color: #A3AAC1;
    bottom: 0;
}*/

.overlay-card::before {
    content: url('../components/Icons/lock.svg');
    position: absolute;
    top: 20px;
    right: 30px;
    width: 20px;
    height: 20px;
    opacity: 1;
}

.ion-materials-row {
    padding-top: 15px;
    padding-left: 5px;
}

.ion-card-content {
    padding: 5px;
}

.ion-description-materials {
    color: #212121;
    /*padding-left: 3.2%;*/
}

.ion-duration {
    padding-left: 10px;
    color: #212121;
}

.spacer-sessions {
    height: 5px;
}