.spacer {
    height: 250px;
}

.color-back-icon {
    color: #212121 ! important;
}

.space-button {
    padding: 20px;
}

.space-button--right {
    padding-right: 40px;
}

.ion-padding-row {
    padding: 20px 30px;
}

.ion-padding-row-title {
    padding: 10px 30px 0;
}

.ion-title-session__strong {
    font-size: var(--font-size-large);
    font-weight: 700;
}

.ion-text-session {
    font-size: var(--font-size-base) !important;
}