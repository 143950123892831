.content-container-row {
    position: relative;
    height: 100%;
}

.top-spacer {
    padding-top: 20px;
}

.content-container-row-icon {
    margin-top: auto;
    margin-bottom: auto;
}

.content-session-completed {
    margin-top: auto;
}

.ion-completed-label {
    font-size: var(--font-size-large);
    font-weight: 600;
}

/* altura do bottom menu quando estamos na versão mobile */
.spacer-home {
    height: 40px;
}

/*Timeline*/

.timeline {
    width: 100%;
    position: relative;
    margin: -20px 0 0 -18px;
}

.timeline:before {
    content: '';
    position: absolute;
    top: 90px;
    bottom: 110px;
    width: 3px;
    background: #758c92;
    left: 46px;
    margin-left: -10px;
    z-index: 1;
}

.timeline .timeline-thumb {
    border-radius: 500px;
    width: 50px;
    z-index: 2;
    position: absolute;
    left: 12.3px;
    padding: 17px;
}

.timeline .timeline-thumb.timeline-icon {
    padding-top: 80px;
    text-align: center;
    color: #212121;
}

.timeline .timeline-thumb.timeline-icon i {
    font-size: 28px;
    margin: 6px 0;
    display: block;
}

ul li {
    list-style-type: none;
}

.padding-card {
    padding: 0px;
    max-height: 300px;
}

.content-session-completed-atual {
    padding-top: 20px;
    padding-bottom: 0;
}

.ion-card {
    max-height: 280px;
    border-radius: 36px;
    padding: 20px;
    background: #FFF;
    margin-bottom: 20px;
}

.ion-card:hover {
    cursor: pointer;
}

/*COLOR LOCK*/
.overlay-card {
    background-color: rgba(229, 229, 229, 1);
}

.overlay-color {
    opacity: 0.7;
}

.timeline-item {
    margin-left: 10px;
}

.timeline .timeline-stats {
    position: relative;
    font-size: 12px;
    color: #7F8C8D;
}

.divider-title {
    background: #e4e4e4;
    padding: 5px 15px;
}