.background-material{
    /*position: absolute;*/
    right: 0;
    left: 0;
    /*bottom: -100px;*/
    height: 100vh;
    border-radius: 30px;
    margin-left: 0;
    margin-right: 0;
}

.ion-icon-play{
    position: absolute;
    float: right;
    bottom: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
}


.ion-label-material-sub{
    font-weight: 400;
    font-size: 16px;
    color:#212121;
}

.no-padding{
    padding: 0;
}

.ion-icon-materials{
    width: 30px;
    height: 22px;
}

.ion-label-material-duration{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    font-size: var(--font-size-base) !important;
    color:#212121;
}

.text-materials{
    font-size: var(--font-size-base) !important;
    color:#212121;
    text-align: justify;
}

.ion-padding{
    padding-bottom: 0;
}

.spacer-div-materials{
    height: 5px;
}

.background-color-content{
    --background: #FFF;
}

.first-title-materials{
    font-size: var(--font-size-large) !important;
    font-weight: 700;
    justify-content: center;
    text-align: center;
}