.kindmap-audio-player-play-button {
    position: absolute;
    float: right;
    bottom: 170px;
    right: 20px;
    width: 30px;
    height: 30px;
    --background: transparent !important;
    --box-shadow: none;
}

.space-material-audio {
    height: 20px;
}

.grid-audio {
    width: 150px;
}

.timer {
    font-weight: 500;
    font-size: 1.5em;
    letter-spacing: 1px;
    color: #212121;
}

.audio-p {
    text-align: left;
    padding-left: 16px;
}