/* Typography */
body {
    font-size: var(--font-size);
    line-height: var(--line-height-base);
    margin: 0;
    padding: 0;
}

.main__content {
    font-size: var(--font-size-min);
    padding: var(--spacing-base);
    margin-top: var(--spacing-base);
    font-style: italic;
}

.main__label {
    font-size: var(--font-size-min) !important;
}

.main__input {
    font-size: var(--font-size-base) !important;
    border: 1px solid #212121 !important;
    border-radius: 6px !important;
}

.main__button--color {
    --background: #F4CE79;
    padding: 6% var(--spacing-base);
}

/********************* ANALISAR DAQUI PARA BAIXO ***************************/

.label-stacked.sc-ion-label-md-h,
.label-floating.sc-ion-label-md-h {
    margin-bottom: 10px;
    color: #212121;
}

.forgot-password-p,
.registo-p,
.login-p {
    font-size: 12px;
    color: #212121;
}

.native-input.sc-ion-input-md {
    padding: 10px;
    border-radius: 6px;
}

.no-border {
    text-decoration: none;
    color: #212121;
    font-weight: 600;
}

.no-border-p {
    text-decoration: none;
    color: #212121;
}

ion-footer ion-toolbar:last-of-type {
    border-top: 0.25px solid #212121;
    padding-bottom: var(--ion-safe-area-bottom, 0);
}

/*Font titulos web*/
.specialDiv {
    display: flex;
    justify-content: center;
    /*font-family: DMSerifDisplay-Regular !important;*/
    font-family: 'Inter', sans-serif;
    font-size: 2.5em;
    text-align: center;
    padding-top: 20px;
    color: #212121;
}


.item-has-focus.label-stacked.sc-ion-label-md-h:not(.ion-color),
.item-has-focus .label-stacked.sc-ion-label-md-h:not(.ion-color),
.item-has-focus.label-floating.sc-ion-label-md-h:not(.ion-color),
.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
    color: #F4CE79;
}

.padding-item {
    padding-top: 10px;
    --background: transparent;
}

.padding-form {
    padding-top: 50px;
}

.padding-item {
    padding: 16px 0px 0px 0px;
}

.email-static {
    font-size: 1.5em;
    font-weight: normal;
}

.logo-link {
    text-decoration: none;
    color: #424242;
}

/* HOME, ABOUT e TEAM PAGE*/
.home-title {
    /*font-family: DMSerifDisplay-Regular !important;*/
    font-family: 'Inter', sans-serif;
    font-size: 6em;
    text-align: center;
    padding-top: 200px;
    color: #212121;
}

.home__title {
    font-family: 'Inter', sans-serif;
    font-size: --var(--font-size-base);
    line-height: var(--line-height-base);
    color: #212121;
}

.video-title {
    /*font-family: DMSerifDisplay-Regular !important;*/
    font-family: 'Inter', sans-serif;
    font-size: 6em;
    text-align: center;
    padding-top: 5px;
    color: #212121;
}

.flex-button {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.button-home {
    --border-radius: 50px;
    --background: #F4CE79;
}

.fullscreen {
    width: 100%;
    height: 100%;
}

.ion-size {
    width: 100%;
    height: 100%;
}


/*MANCHAS*/
.animation-svg {
    position: absolute;
    z-index: 0;
    width: 100vh;
    left: -100px;
    bottom: 320px;
}

.animation-svg2 {
    z-index: 0;
    position: absolute;
    top: 500px;
    right: -200px;
}

.animation-svg3 {
    z-index: 0;
    position: absolute;
    padding-bottom: 30px;
}

.view-content {
    z-index: 1;
}

.title-bloco03 {
    /*font-family: DMSerifDisplay-Regular !important;*/
    font-family: 'Inter', sans-serif;
    font-size: 2em;
    text-align: center;
    color: #212121;
    padding: 25px;
}

.title-bloco04 {
    /*font-family: DMSerifDisplay-Regular !important;*/
    font-family: 'Inter', sans-serif;
    font-size: 6em;
    text-align: left;
    color: #212121;
}

.default-svg {
    position: block;
    width: 20vh;
    margin-left: auto;
    margin-right: auto;
}

.block-text {
    text-align: right !important;
}

.two-blocks {
    display: block;
}

.default-svg2 {
    position: block;
    width: 30vh;
    margin-left: auto;
    margin-right: auto;
    /*background-position*/
    /*background-size*/
}