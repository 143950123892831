/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

  /** Font Gibson **/
  /*--ion-font-family: ‘GibsonRegular’;*/
  --ion-font-family: 'Inter', 'DM Serif Display', 'Roboto', 'Roboto Slab', sans-serif;
  --other-font-family: 'Roboto', 'Inter', 'Roboto Slab', sans-serif

  /* background colors */
  --public-bg-color: #fff;
  --private-bg-color: hsla(29, 86%, 75%, 0.7);

  /* small, base and large text size */
  /*tamanho de fonte de 16px a 20px em desktop e entre 14px e 18px em telas de dispositivos móveis*/
  --font-size: 16px;
  --font-size-small: clamp(0.694rem, 2vw + 0.5rem, 0.8333rem);/*clamp(0.8rem, 2vw + 0.5rem, 1rem);*/
  --font-size-base: clamp(0.8333rem, 3vw + 0.5rem, 1rem);/*clamp(1rem, 3vw + 0.5rem, 1.2rem);*/
  --font-size-large: clamp(1.2rem, 3vw + 0.8rem, 1.5rem);

  /* title and sub-titles text size */
  --font-size-title-h1: clamp(2.488rem, 3vw + 0.8rem, 2.986rem);
  --font-size-title-h2: clamp(2.074rem, 3vw + 0.8rem, 2.488rem);
  --font-size-title-h3: clamp(1.728rem, 3vw + 0.8rem, 2.074rem);
  --font-size-title-h4: clamp(1.44rem, 3vw + 0.8rem, 1.728rem);
  --font-size-title-h5: clamp(1.2rem, 3vw + 0.8rem, 1.44rem);
  --font-size-title-h6: clamp(1rem, 3vw + 0.8rem, 1.2rem);


  /* line height of text size */
  --line-height-min: 1.4;
  --line-height-base: 1.6;
  --line-height-max: 1.8;

  --font-weight-bold: 700;
  --color-bg-light: #ccc;
  --spacing-base: 2%;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}


/*SIZES*/
.hide-xs .hide-sm .hide-md .hide-lg .hide-xl {
  display: none;
}

/*HOVER*/
.no-ripple {
  --ripple-color: transparent;
}

/*LOGO*/

.logo {
  width: 170px;
  height: 50px;
  padding-left: 20px;
  padding-top: 16px;
}

p,
h6 {
  color: #212121;
}

ion-title {
  --color: #212121;
}

/* dinamic text size */
html {
  font-size: var(--font-size);
}

@media (min-width: 576px) {
  html {
    font-size: var(--font-size-base);
  }
}

@media (min-width: 768px) {
  html {
    font-size: var(--font-size-base);
  }
}

@media (min-width: 992px) {
  html {
    font-size: var(--font-size-base);
  }
}