h1,
p {
  margin: 0;
  padding: 0;
}

.logo__home {
  width: 80%;
  height: auto;
  margin: 30px;
}

.logo__team {
  width: 90%;
  height: auto;
  margin: 0 auto;
  padding: 5px;
}

.row--align__bottom {
  align-items: center;
  justify-content: center;
}

/* Home container */
.home__container {
  /*min-height: 100vh;*/
  display: flex;
  font-size: var(--font-size-base);
  font-weight: normal;
  overflow: auto;
}

/* Home content layout */
.home__content {
  width: 100%;
  /*-height: 100vh;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-base);
  font-size: var(--font-size-base);
  z-index: -1 !important;
}

.home__content--center {
  text-align: center;
}

.home__content--small-width {
  padding: 20px;
}

.home__content--text-larger {
  font-size: var(--font-size-large);
}

/* Home title */
.home__title {
  font-size: var(--font-size-title);
  line-height: var(--line-height-base);
  margin-bottom: var(--spacing-base);
}

.home__title--strong {
  font-weight: var(--font-weight-bold);
}

.home__title--text-large {
  font-size: var(--font-size-large);
}

/* Text content */
.home__text {
  width: 100%;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  margin-bottom: var(--spacing-base);
}

/* Button */
.home__button {
  margin: var(--spacing-base);
  padding: 0.75em 1.5em;
  font-size: var(--font-size-base);
  cursor: pointer;
  border: none;
  color: white;
  border-radius: 5px;
  --background: #82bf93;
}

/* Prevent text selection for all elements */
.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* SCROLLBAR */
ion-content::part(scroll)::-webkit-scrollbar {
    width: 16px;
}

ion-content::part(scroll)::-webkit-scrollbar-track {
    /*border-radius: 8px;*/
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
}

ion-content::part(scroll)::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: #82bf93;
}