.meditate-p {
    font-size: var(--font-size-base) !important;
    font-weight: lighter;
    color: #212121;
    text-align: left;
}

h1.meditate-title {
    font-size: var(--font-size-large) !important;
    font-weight: bold;
    color: #212121;
}

.kindmap-meditation {
    --background: #fff;
}

.timer {
    font-size: var(--font-size-min) !important;
    font-weight: 500;
    letter-spacing: 1px;
    color: #212121;
}

.ion-icon-control {
    width: 20px;
    height: 50px;
}

/*.waveform-container-wave {
    margin-left: 25px;
}*/

.waveform-container-controls {
    display: flex;
    place-content: center;
    height: 100%;
}


.button-meditation {
    height: 100px !important;
    --ripple-color: transparent;
}

.ion-icon-control-play {
    width: 50px;
    height: 50px;
}

.borderline {
    margin-top: 20px;
    height: 1px;
    border-width: 0;
    box-sizing: content-box;
    background: #A7A6A6;
}