.ion-padding-row{
    padding: 20px 30px;
}

.ion-padding-row-title{
    padding: 10px 30px 0;
    color:#212121;
}

.ion-title-session{
    font-size: var(--font-size-base);
    font-weight: 700;
    color:#212121;
}

.space-kind-materials{
    height: 100px;
}