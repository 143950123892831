.ion-card-profile{
    border-radius: 36px;
    padding: 36px;
    background: #BFB9DB ;
    margin-top: 30px;
    margin-bottom: 30px;
}

.title-default {
    color:#212121;
    font-size: 24px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.number-sessions{
    font-weight: 500;
    font-size: 16px!important;
}

.padding-row-settings{
    padding-bottom: 20px;
}

.header-ios ion-toolbar:last-of-type {
    --border-width: 0 0 0.55px;
    --background: #A3AAC1;
}

.activity-row{
    text-align: center;
    background: #DFC192;
    color:#212121;
    border-radius: 36px;
    padding: 20px;
    margin:-36px;
}

.profile-label{
    color:#212121;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
}

.profile-text{
    color:#212121;
    font-size: 14px;
}

.letter-row{
    font-size: 12px;
}

.spacer-div{
    height: 70px;
}

.spacer-div-final{
    height: 50px;
}

.grid-ioncol{
    display: grid;
    padding-left: 20px;
    padding: 10px;
}

.ion-icon-settings{
    padding: 20px;
    background: #CCCCCC;
    border-radius: 16px;
}

.grid-label{
    color:#212121;
    font-size: 16px;
    font-weight: 500;
}

.grid-text{
    color:#212121;
    font-size: 14px;
}

.settings-label{
    color:#212121;

}

.settings-label-diary{
    font-weight: 600;
    color:#212121;
    font-size: 18px;
}

.spacer-settings{
    height: 20px;
}

.copyright{
    float: right;
}

.copyright-settings{
    font-size: 14px;
}

.link-settings-border{
    border-radius: 36px;
    padding: 20px;
    background: #CCCCCC;
    margin-top: 0px;
}

.ion-item {
    --background: none;
    opacity:1;
    --border-color:#212121;
    --detail-icon-color:#212121;
    --stroke:#212121;
    --padding-start:none;
  }

  .button-logout{
      --border-radius:none;
      height: 80px;
  }

  .label-logout{
      font-weight: 600;
      text-transform: uppercase;
  }