.ion-title-sessions{
    font-size: var(--font-size-base);
    font-weight: bold;
    padding: 16px;
}

.ion-padding-card{
    margin-top: 14px;
}

.ion-padding-without-bottom{
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 50px;
}