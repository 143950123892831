.icon-fab-diary {
    width: 60px;
    height: 60px;
    float: right;
}

ion-fab-button {
    --background: transparent !important;
}

.fab-icon-align {
    position: fixed;
    bottom: 90px;
    right: 27px;
}

.padding-materials {
    padding: 16px;
}