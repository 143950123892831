.player-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height/width = 9/16 = 56.25%) */
  height: 0;
  overflow: hidden;
  text-align: center;
}

.player-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.player-wrapper iframe {
  touch-action: auto !important;
}