
/* Menu Pós-login */
.tab-mobile {
  background-color: #F4CE79;
}

.tabar-mobile {
  background-color: #F4CE79;
  bottom: 20px;
  position: relative;
  border-radius: 25px 25px 0px 0px;
  width: 100%;
  height: auto;
  border-top: none;
  margin: -20px auto;
  padding: 0 0 15px;
  height: 55px;
}

/* Default button styles */
.tabutton-mobile {
  background-color: #F4CE79;
  --color: #212121;
  --color-selected: #212121;
  --padding-bottom: 0px;
  --padding-top: 8px;
  transition: color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
}

/* Active button */
.tabutton-mobile.active {
  color: #82bf92;
  font-weight: bold;
  border-bottom: 3px solid #82bf92;
}

.tabutton-mobile::before {
  background-color: transparent;
  display: block;
  content: "";
  margin: 0 auto;
  width: 20px;
  height: 2px;
}

.tabutton-mobile.tab-selected::before {
  background-color: #212121;
}

.no-ripple {
  --ripple-color: transparent;
}