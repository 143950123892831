.padding-materials {
    padding: 16px;
}

.content-tips {
    height: 174.94px;
    border-radius: 30px;
    background-color: #CCCCCC;
    display: inline-block;
}

.icon-tips {
    width: 20px;
    height: 20px;
    margin-top: 4px;
}