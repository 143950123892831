.logo {
  background-color: transparent;
}

.green-hover {
  display: inline-block;
  text-decoration: none;
  text-transform: capitalize;
  padding-top: 10px;
}

.green-hover::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #82bf92;
  transition: width .3s;
}

.green-hover:hover::after {
  width: 100%;
}

.green-hover-login:hover {
  color: #82bf92;
}

.no-ripple {
  --ripple-color: transparent;
}

/* Active button */
.no-ripple.active {
    color: #82bf92; /* Green color for active tab */
    font-weight: bold;
    border-bottom: 3px solid #82bf92; /* Optional underline */
}