.ion-page {
  z-index: 0 !important;
}

/* Default styles for larger screens (horizontal layout) */
.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-x: hidden;
}

.column {
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-right: 1.5px solid #000;
  background-color: #f4f4f4;
  color: #333;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.column:last-child {
  border: none;
}

.expanded {
  width: auto;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.expanded .column-p {
  border-right: 1.5px solid #000;
  width: 50px;
  height: 100vh;
  padding: 0 25px;
}

.column.active {
  background-color: #82bf93;
  color: white;
  font-weight: bold;
}

.column-p {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  cursor: pointer;
}

.column-p div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}

p.number {
  margin: 0;
  line-height: 1;
  padding-top: 25px;
  transform: rotate(180deg);
  white-space: nowrap;
  writing-mode: vertical-rl;
}

.additional-content {
  border-left: 1.5px solid #000;
  height: 100%;
  margin-top: 10px;
  padding-right: 10px;
  display: none;
}

/* Mobile styles: switch from horizontal columns to vertical rows */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    width: 100vw;
    height: 100vh;
  }

  .column {
    width: 100%;
    max-height: 100vh;
    flex-direction: row;
    border-right: none;
    border-bottom: 1.5px solid #000;
  }

  .column:last-child {
    border: none;
  }

  /* Adjust the inner container for the column content */
  .column-p {
    height: 50px;
    flex-direction: row;
    padding: 10px;
  }

  /* Expanded area adjustments if needed */
  .expanded {
    width: 100%;
    height: 50px;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .expanded .column-p {
    border-right: none;
    border-bottom: 1.5px solid #000;
    width: 100%;
    height: 50px;
    padding: 0 10px;
  }

  /* Optional: Adjust the rotated number text for better readability on mobile */
  p.number {
    transform: none;
    writing-mode: horizontal-tb;
    padding-top: 0;
  }
}