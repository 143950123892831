.bottom-drawer {
    position: absolute;
    right: 0;
    left: 0;
    border-radius: 30px;
    margin-left: 0;
    margin-right: 0;
  }

.button-drawer-header-container{
    text-align: center;
}

.button-drawer-header {
    background-color: #A7A6A6;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 8px;
    border-radius: 10px;
    height: 12px;
    width: 100px;
}

.ion-label-session{
    font-size: var(--font-size-base);
    font-weight: 500;
}

.ion-label-session {
  display: inline-block;
  padding-left: 12px;
  margin: 0;
}

.ion-label-session-enable{
    color:#212121;
}
.ion-label-session-disable{
    color:#888888;
}

.card-content-session{
    display: inline-block;
}

.ion-padding-left{
    padding-left: 35px;
}

.ion-card-content-container{
    height:100%;
}

.ion-card-content-container-grid{
    height: 100%;
    margin-top: 10px;
}

.icon-title-container {
  display: inline-block;
}
